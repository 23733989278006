import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Link,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClear } from "react-icons/md";
import { JPos, setSearchBody } from "../../../../../app/slices/jomla/posSlice";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AiOutlineTransaction } from "react-icons/ai";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { SelectJRep } from "../../../../../components/Selectors/SelectJRep";
import Permission from "../../../../../components/userPermission/userPermission";
import { SelectProvince } from "../../../../../components/Selectors/SelectProvince";
import { SelectCity } from "../../../../../components/Selectors/SelectCity";
import { setCityTable } from "../../../../../app/slices/provincesSlice";

const JPosTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);
  const _JPos = useSelector((state) => state.jPos.jPos);
  const loading = useSelector((state) => state.jPos.loading);
  const _SearchBody = useSelector((state) => state.jPos.searchBody);
  const reps = useSelector((state) => state.user.jRepList.data);
  const _provinces = useSelector((state) => state.provinces.provinces.data);
  const _Cities = useSelector((state) => state.provinces.cities.data);

  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "pos_number",
      headerName: "رقم النقطة",
      flex: 1,
    },
    {
      field: "province_name",
      headerName: "العنوان",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">{params.row.province_name} - </Typography>
          <Typography variant="body2">{params.row.city}</Typography>
        </Stack>
      ),
    },
    {
      field: "representative",
      headerName: "المندوب",
      flex: 1,
    },
    {
      field: "total_amount",
      headerName: "المبيعات (IQD)",
      flex: 1,
      renderCell: (params) =>
        parseInt(params.row.total_amount).toLocaleString(),
    },
    {
      field: "total_remaining",
      headerName: "الديون (IQD)",
      flex: 1,
      renderCell: (params) =>
        parseInt(params.row.total_remaining).toLocaleString(),
    },
    {
      field: "order_count",
      headerName: "عدد الطلبات",
      flex: 1,
      renderCell: (params) => parseInt(params.row.order_count).toLocaleString(),
    },
    {
      field: "last_order",
      headerName: "اخر طلبية",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Link
            onClick={() =>
              navigate(`/app/wholesale/order/${params.row.last_j_order_id}`)
            }
            style={{ cursor: "pointer" }}
          >
            <Typography variant="body2">
              #{params.row.last_j_order_number}
            </Typography>
          </Link>
          <Typography variant="body2">
            {params.row.last_j_order_amount?.toLocaleString()} IQD
          </Typography>
          <Typography variant="body2">
            {dayjs(params.row.last_j_order_date).format("YYYY-MM-DD")}
          </Typography>
        </Stack>
      ),
      valueGetter: (params) => {
        const orderNumber = `#${params.row.last_j_order_number}`;
        const orderAmount = `${params.row.last_j_order_amount?.toLocaleString()} IQD`;
        const orderDate = dayjs(params.row.last_j_order_date).format(
          "YYYY-MM-DD"
        );
        return `${orderNumber} - ${orderAmount} - ${orderDate}`;
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 2,
      renderCell: (params) => {
        return (
          <Permission permission="j_order_transaction_view">
            <Tooltip title="المناقلات">
              <IconButton
                onClick={() => {
                  localStorage.setItem("isWholeSale", "1");
                  navigate("/app/wallets/transactions/" + params.row.user_id);
                }}
              >
                <AiOutlineTransaction />
              </IconButton>
            </Tooltip>
          </Permission>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JPos.jPos(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        j_rep_id: search.j_rep_id,
        city_ids: search.city_ids,
        province_id: search.province_id,
        has_j_balance: search.has_j_balance === 1 ? search.has_j_balance : null,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search]);

  useEffect(() => {
    const selectedCities = _provinces?.filter((x) =>
      x.id === _SearchBody.province_id ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_SearchBody.province_id, _provinces]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Stack direction="row" spacing={2} sx={{ marginX: 2 }}>
          <Typography color="primary">
            المجموع الكلي : {_JPos.total_amount?.toLocaleString()} (IQD)
          </Typography>
          <Typography>
            مجموع الديون : {_JPos.total_debt?.toLocaleString()} (IQD)
          </Typography>
        </Stack>
        <GridPagination />
      </Stack>
    );
  }

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExportContainer>
        <MenuItem
          onClick={() => {
            dispatch(JPos.exportExcel(_SearchBody));
          }}
        >
          تصدير ملف اكسل
        </MenuItem>
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن الاسم او رقم النقطة "
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectJRep
              disabled={loading}
              onChange={(event, value) => {
                setSearch({
                  ...search,
                  j_rep_id: value ? value.id : null,
                });
              }}
              value={
                reps.filter((user) => user.id === _SearchBody.j_rep_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectProvince
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  province_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _provinces?.filter((x) => x.id === search.province_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectCity
              multiple
              onChange={(e, value) => {
                var ids = value.map((x) => x.id);
                setSearch({
                  ...search,
                  city_ids: value ? ids : null,
                });
              }}
              value={
                _Cities?.filter((x) => search.city_ids?.includes(x.id)) ?? []
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={search.has_j_balance === 1 ? true : false}
                    onChange={(e) =>
                      setSearch({
                        ...search,
                        has_j_balance: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                }
                label="ديون الجملة"
              />
              <Tooltip title="تصدير ملف اكسل">
                <IconButton
                  onClick={() => {
                    dispatch(JPos.exportExcel(_SearchBody));
                  }}
                >
                  <BsFillFileEarmarkExcelFill />
                </IconButton>
              </Tooltip>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      search: "",
                      has_j_balance: null,
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              {_JPos.data ? (
                <DataGrid
                  rows={_JPos.data.map((x, i) => {
                    return {
                      ...x,
                      index: i + 1 + pageSize * currentPage,
                    };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_JPos.total}
                  loading={loading}
                  rowHeight={75}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: CustomFooter,
                    Toolbar: _Role === "representative" ? null : customToolbar,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JPosTable;
