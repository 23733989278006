import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const productsOrdersApi = factory.get("productsOrders");
const initialState = {
  searchBody: {},
  loading: false,
  addProductsOrder: {
    dialog: false,
    form: {
      pos_id: null,
      product_id: null,
      count: 1,
      total_price: 0,
      note: "",
    },
  },
  productsOrder: {
    dialog: false,
    form: {
      order_id: "",
      supplier_id: "",
    },
  },
  productsOrders: {
    data: [],
    total_payments: 0,
    total_remaining: 0,
    total_amount: 0,
    total: 0,
  },
  transactions: {
    dialog: false,
    order_id: null,
    data: [],
    total: 0,
  },
  supplierChange: {
    dialog: false,
    order_id: null,
    supplier_id: null,
  },
  productsOrdersDetails: {
    dialog: false,
    order_id: null,
    data: {},
  },
};

export const productsOrdersSlice = createSlice({
  name: "productsOrders",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.productsOrder.dialog = !state.productsOrder.dialog;
      state.productsOrder.form = payload;
    },
    setAddDialog: (state, { payload }) => {
      state.addProductsOrder.dialog = !state.addProductsOrder.dialog;
      // state.addProductsOrder.form = payload
    },
    setSupplierChangeDialog: (state, { payload }) => {
      state.supplierChange.dialog = !state.supplierChange.dialog;
      state.supplierChange.order_id = payload.order_id;
      state.supplierChange.supplier_id = payload.supplier_id;
    },
    setDataTable: (state, { payload }) => {
      state.productsOrders.data = payload.data.data.data;
      state.productsOrders.total_payments = payload.data.data.total_payments;
      state.productsOrders.total_remaining = payload.data.data.total_remaining;
      state.productsOrders.total_amount = payload.data.total_amount;
      state.productsOrders.total = payload.data.total;
    },
    setTransactionsDialog: (state, { payload }) => {
      state.transactions.dialog = !state.transactions.dialog;
      state.transactions.order_id = payload;
    },
    setTransactionsDataTable: (state, { payload }) => {
      state.transactions.data = payload.data.data;
      state.transactions.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.productsOrder.form = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.productsOrder.form = initialState.productsOrder.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setProductsOrdersDetailsDialog: (state, { payload }) => {
      state.productsOrdersDetails.dialog = !state.productsOrdersDetails.dialog;
      state.productsOrdersDetails.data = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setTransactionsDataTable,
  setTransactionsDialog,
  setSupplierChangeDialog,
  setProductsOrdersDetailsDialog,
  setAddDialog,
} = productsOrdersSlice.actions;

export default productsOrdersSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("productsOrders"));
    const res = await productsOrdersApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("productsOrders"));
  } catch (err) {
    dispatch(setLoading("productsOrders"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("productsOrders"));
    const res = await productsOrdersApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `products_orders.xlsx`;
    a.click();
    dispatch(setLoading("productsOrders"));
  } catch (err) {
    dispatch(setLoading("productsOrders"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, searchBody) => async (dispatch) => {
  try {
    await productsOrdersApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setAddDialog());
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const cancel = (id, data, searchBody) => async (dispatch) => {
  try {
    await productsOrdersApi.cancel(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const changeStatus = (data, searchBody) => async (dispatch) => {
  try {
    const res = await productsOrdersApi.changeStatus(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const cancelOrder = (data, searchBody) => async (dispatch) => {
  try {
    const res = await productsOrdersApi.cancelOrder(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const paymentStatus = (data, searchBody) => async (dispatch) => {
  try {
    const res = await productsOrdersApi.paymentStatus(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const note = (data, searchBody) => async (dispatch) => {
  try {
    const res = await productsOrdersApi.note(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const transactions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("productsOrders"));
    const res = await productsOrdersApi.transactions(params);
    dispatch(setTransactionsDataTable(res));
    dispatch(setLoading("productsOrders"));
  } catch (err) {
    dispatch(setLoading("productsOrders"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const orderDailyPayments = (data, searchBody) => async (dispatch) => {
  try {
    const res = await productsOrdersApi.orderDailyPayments(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const supplierChange = (data, searchBody) => async (dispatch) => {
  try {
    await productsOrdersApi.supplierChange(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(
      setSupplierChangeDialog({
        order_id: null,
        supplier_id: null,
      })
    );
    dispatch(getAll(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const ProductsOrder = {
  getAll,
  cancel,
  changeStatus,
  cancelOrder,
  note,
  transactions,
  paymentStatus,
  orderDailyPayments,
  supplierChange,
  create,
  exportExcel,
};
