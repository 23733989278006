import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { User } from "./userSlice";
const walletApi = factory.get("wallet");
const initialState = {
  transactionsSearchBody: {
    user_type: "pos",
    take: 15,
    skip: 0,
  },
  reload: false,
  recivedCashDialog: false,
  topUpDialog: false,
  transferDialog: false,
  withdrawDialog: false,
  withdrawPosDialog: false,
  correctBalanceDialog: false,
  clearanceDialog: false,
  wallet: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      name: "",
    },
  },
  transactions: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  supplierTransactions: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  repCash: {
    loading: false,
    data: [],
    total: 0,
  },
  transactionType: [],
  payCashSupplierDialog: false,
  receiveCashSupplierDialog: false,
  initialPosInfo: {
    dialog: false,
    pos_id: null,
    form: {
      balance: "",
      debt: "",
    },
  },
  monthlyRep: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.wallet.dialog = !state.wallet.dialog;
    },
    setRecivedCashDialog: (state, action) => {
      state.recivedCashDialog = !state.recivedCashDialog;
      state.reload = !state.recivedCashDialog ? !state.reload : state.reload;
    },
    setTransferDialog: (state, action) => {
      state.transferDialog = !state.transferDialog;
      state.reload = !state.transferDialog ? !state.reload : state.reload;
    },
    setTopupDialog: (state, action) => {
      state.topUpDialog = !state.topUpDialog;
      state.reload = !state.topUpDialog ? !state.reload : state.reload;
    },
    setWithdrawDialog: (state, action) => {
      state.withdrawDialog = !state.withdrawDialog;
      state.reload = !state.withdrawDialog ? !state.reload : state.reload;
    },
    setWithdrawPosDialog: (state, action) => {
      state.withdrawPosDialog = !state.withdrawPosDialog;
      state.reload = !state.withdrawPosDialog ? !state.reload : state.reload;
    },
    setCorrectBalanceDialog: (state, action) => {
      state.correctBalanceDialog = !state.correctBalanceDialog;
      state.reload = !state.correctBalanceDialog ? !state.reload : state.reload;
    },
    setClearanceDialog: (state, action) => {
      state.clearanceDialog = !state.clearanceDialog;
      state.reload = !state.clearanceDialog ? !state.reload : state.reload;
    },
    setDataTable: (state, { payload }) => {
      state.transactions.data = payload.data.data;
      state.transactions.total = payload.data.total;
      state.transactions.total_amount = payload.data.total_amount;
    },
    setSupplierTransactions: (state, { payload }) => {
      state.supplierTransactions.data = payload.data.data;
      state.supplierTransactions.total = payload.data.total;
      state.supplierTransactions.total_amount = payload.data.total_amount;
    },
    setTransactionTypes: (state, { payload }) => {
      state.transactionType = payload.data.data;
    },
    setRepCashTable: (state, { payload }) => {
      state.repCash.data = payload.data.data;
      state.repCash.total = payload.data.total;
    },
    setMonthlyRepData: (state, { payload }) => {
      state.monthlyRep.data = payload.data.data;
      state.monthlyRep.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.wallet.form.id = payload.id;
      state.wallet.form.name = payload.name;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setMonthlyRepLoading: (state, action) => {
      state.monthlyRep.loading = !state.monthlyRep.loading;
    },
    resetForm: (state, action) => {
      state.wallet.form = initialState.wallet.form;
    },
    setPayCashSupplierDialog: (state, action) => {
      state.payCashSupplierDialog = !state.payCashSupplierDialog;
      state.reload = !state.payCashSupplierDialog
        ? !state.reload
        : state.reload;
    },
    setReceiveCashSupplierDialog: (state, action) => {
      state.receiveCashSupplierDialog = !state.receiveCashSupplierDialog;
      state.reload = !state.receiveCashSupplierDialog
        ? !state.reload
        : state.reload;
    },
    setInitialPosInfoDialog: (state, { payload }) => {
      state.initialPosInfo.dialog = !state.initialPosInfo.dialog;
      state.initialPosInfo.pos_id = payload;
    },
    setTransactionsSearchBody: (state, { payload }) => {
      state.transactionsSearchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  setSupplierTransactions,
  setTransactionTypes,
  resetForm,
  setTransferDialog,
  setRecivedCashDialog,
  setTopupDialog,
  setWithdrawDialog,
  setClearanceDialog,
  setRepCashTable,
  setPayCashSupplierDialog,
  setReceiveCashSupplierDialog,
  setInitialPosInfoDialog,
  setWithdrawPosDialog,
  setCorrectBalanceDialog,
  setMonthlyRepData,
  setMonthlyRepLoading,
  setTransactionsSearchBody,
} = walletSlice.actions;

export default walletSlice.reducer;

//axios
const getAllTransaction = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    const res = await walletApi.getTransactions(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};
const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    const res = await walletApi.export({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `wallet transactions.xlsx`;
    a.click();
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(setLoading("wallet"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getMonthlyRep = (params) => async (dispatch) => {
  try {
    dispatch(setMonthlyRepLoading("wallet"));
    const res = await walletApi.monthlyRep(params);
    dispatch(setMonthlyRepData(res));
    dispatch(setMonthlyRepLoading("wallet"));
  } catch (err) {
    dispatch(setMonthlyRepLoading("wallet"));
    throw new Error(err);
  }
};
const receiveCashRep = (data, transactionsSearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    await walletApi.receiveCashRep(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("wallet"));
    dispatch(setClearanceDialog());
    dispatch(getAllTransaction(transactionsSearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};

const receiveCashPos = (data, transactionsSearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    await walletApi.receiveCashPos(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAllTransaction(transactionsSearchBody));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};
const topupRep = (data, transactionsSearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    await walletApi.topupRep(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAllTransaction(transactionsSearchBody));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};
const transfer = (data, transactionsSearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    await walletApi.transfer(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAllTransaction(transactionsSearchBody));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};
const withdraw = (data, transactionsSearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    await walletApi.withdraw(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAllTransaction(transactionsSearchBody));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};
const withdrawPos = (data, transactionsSearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    await walletApi.withdrawPos(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAllTransaction(transactionsSearchBody));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};

const correctBalance = (data, transactionsSearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    await walletApi.correctBalance(data);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAllTransaction(transactionsSearchBody));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};
const getSupplierTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    const res = await walletApi.getSupplierTransactions(params);
    dispatch(setSupplierTransactions(res));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};

const getRepCash = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("repCash"));
    const res = await walletApi.repCash(data);
    dispatch(setRepCashTable(res));
    dispatch(setLoading("repCash"));
  } catch (err) {
    dispatch(setLoading("repCash"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const getTransactionTypes = () => async (dispatch) => {
  try {
    dispatch(setLoading("wallet"));
    const res = await walletApi.transactionTypes();
    dispatch(setTransactionTypes(res));
    dispatch(setLoading("wallet"));
  } catch (err) {
    dispatch(setLoading("wallet"));
    throw new Error(err);
  }
};

const receiveCashSupplier = (data) => async (dispatch) => {
  try {
    await walletApi.receiveCashSupplier(data);
    dispatch(
      showNotification({
        message: "تم استلام حساب الفواتير بنجاح",
        type: "success",
      })
    );
    dispatch(setReceiveCashSupplierDialog());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const payCashSupplier = (data) => async (dispatch) => {
  try {
    await walletApi.payCashSupplier(data);
    dispatch(
      showNotification({
        message: "تم تسديد المبالغ بنجاح",
        type: "success",
      })
    );
    dispatch(setPayCashSupplierDialog());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const initialBalanceAndDebt = (id, data, searchBody) => async (dispatch) => {
  try {
    await walletApi.initialBalanceAndDebt(id, data);
    dispatch(
      showNotification({
        message: "تم الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setInitialPosInfoDialog(null));
    dispatch(User.getRepAndPos(searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const checkBalanceDifference = (user_id) => async (dispatch) => {
  try {
    await walletApi.checkBalanceDifference(user_id);
    dispatch(User.getByIdData(user_id));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const checkBalanceDifferenceDate = (data) => async (dispatch) => {
  try {
    const res = await walletApi.checkBalanceDifferenceDate(data);
    dispatch(
      showNotification({
        message: "differrence = " + res.data.data.diff,
        type: res.data.data.diff == 0 ? "success" : "error",
      })
    );
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Wallet = {
  getAllTransaction,
  receiveCashPos,
  receiveCashRep,
  topupRep,
  transfer,
  withdraw,
  withdrawPos,
  correctBalance,
  getSupplierTransactions,
  getTransactionTypes,
  getRepCash,
  payCashSupplier,
  receiveCashSupplier,
  initialBalanceAndDebt,
  getMonthlyRep,
  checkBalanceDifference,
  checkBalanceDifferenceDate,
  exportExcel
};
