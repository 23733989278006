import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTransactions(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/wallet/all-transactions?${params}`);
  },
  topupRep(data) {
    return api.post(`/api/v1/wallet/top-up-rep`, data);
  },
  transfer(data) {
    return api.post(`/api/v1/wallet/transfer`, data);
  },
  receiveCashRep(data) {
    return api.post(`/api/v1/wallet/receive-debt-rep`, data);
  },
  receiveCashPos(data) {
    return api.post(`/api/v1/wallet/receive-debt-pos`, data);
  },
  withdraw(data) {
    return api.post(`/api/v1/wallet/withdraw`, data);
  },
  withdrawPos(data) {
    return api.post(`/api/v1/wallet/withdraw_pos`, data);
  },
  correctBalance(data) {
    return api.post(`/api/v1/wallet/correct-balance`, data);
  },
  getSupplierTransactions(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/wallet/transactions/supplier?${params}`);
  },
  repCash(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/wallet/rep/clearance?${params}`);
  },
  transactionTypes() {
    return api.get(`/api/v1/wallet/transactions/types`);
  },
  receiveCashSupplier(data) {
    return api.post(`/api/v1/wallet/supplier/receive-cash`, data);
  },
  payCashSupplier(data) {
    return api.post(`/api/v1/wallet/supplier/pay-cash`, data);
  },
  initialBalanceAndDebt(id, data) {
    return api.post(`/api/v1/pos/initial/${id}`, data);
  },
  monthlyRep(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/monthly-rep?${params}`);
  },
  checkBalanceDifference(user_id) {
    return api.post(`/api/v1/wallet/check-balance-difference/${user_id}`);
  },
  checkBalanceDifferenceDate(data) {
    return api.post(`/api/v1/wallet/check-balance-difference-date/`, data);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/wallet/all-transactions?${params}`, { responseType: "blob" });
  },
};
