import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/order/products?${params}`);
  },
  export(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/order/products?${params}`, {
      responseType: "blob",
    });
  },
  note(data) {
    return api.post(`/api/v1/order/products/note`, data);
  },
  create(data) {
    return api.post(`/api/v1/order/products/add`, data);
  },
  changeStatus(data) {
    return api.post(`/api/v1/order/products/action`, data);
  },
  cancel(id, data) {
    return api.delete(`/api/v1/products/${id}`, data);
  },
  cancelOrder(data) {
    return api.post(`/api/v1/products/cancel_order`, data);
  },
  paymentStatus(data) {
    return api.post(`/api/v1/order/products/payment-status`, data);
  },
  transactions(id) {
    return api.get(`/api/v1/wallet/all-transactions?order_id=${id}`);
  },
  orderDailyPayments(data) {
    return api.post(`/api/v1/products/order-daily-payment`, data);
  },
  supplierChange(data) {
    return api.post(`/api/v1/order/products/change-supplier`, data);
  },
  getById(id) {
    return api.post(`/api/v1/order/products/${id}`);
  },
};
