import {
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JReports } from "../../../../../../app/slices/jomla/reportsSlice";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import { SelectJItem } from "../../../../../../components/Selectors/jomla/SelectJItem";
import { JItems } from "../../../../../../app/slices/jomla/itemsSlice";
import { SelectJSubCategory } from "../../../../../../components/Selectors/jomla/SelectJSubCategory";

const JItemsSummaryTotal = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    startDate: dayjs().format("YYYY-MM-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
    j_item_id: null,
    j_category_id: null,
  });

  const _JItemsSummaryTotal = useSelector(
    (state) => state.jReports.jItemsSummaryTotal
  );
  const loading = useSelector((state) => state.jReports.loading);
  const reportTypes = useSelector((state) => state.jReports.reportTypes);
  const _JItems = useSelector((state) => state.JItems.JItemsList.data);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "j_item_name",
      headerName: "اسم المنتج",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            fontWeight: params.row.j_item_id === "new" ? "bold" : "normal",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "j_item_code",
      headerName: "Code",
      flex: 0.5,
    },
    {
      field: "total_count",
      headerName: "العدد المباع",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            fontWeight: params.row.j_item_id === "new" ? "bold" : "normal",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "total_sum",
      headerName: "مجموع السعر",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              fontWeight: params.row.j_item_id === "new" ? "bold" : "normal",
            }}
          >
            {params.row.total_sum?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "total_revenue",
      headerName: "مجموع الربح",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              fontWeight: params.row.j_item_id === "new" ? "bold" : "normal",
            }}
          >
            {params.row.total_revenue?.toLocaleString()}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      JReports.jItemsSummaryTotal({
        start_date: search.startDate,
        end_date: search.endDate,
        j_item_id: search.j_item_id,
        j_category_id: search.j_category_id,
      })
    );
  }, [dispatch, reportTypes, search]);

  useEffect(() => {
    dispatch(JItems.getAllList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "مجموع حركات المادة | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_JItemsSummaryTotal.total_amount?.toLocaleString()}{" "}
          (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJItem
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_item_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JItems.filter((x) => x.id === search.j_item_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJSubCategory
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_category_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JItems.filter((x) => x.id === search.j_category_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    startDate: dayjs().format("YYYY-MM-01"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                    j_item_id: null,
                  });
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "80vh", width: "100%" }}>
              <DataGrid
                rows={[
                  ...(_JItemsSummaryTotal.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  })) ?? []),
                  {
                    j_item_id: "new",
                    index: "",
                    j_item_name: "المجموع",
                    total_count: _JItemsSummaryTotal.total_debt.count,
                    total_sum: _JItemsSummaryTotal.total_debt.total,
                    total_revenue: _JItemsSummaryTotal.total_debt.revenue,
                  },
                ]}
                getRowId={(params) => params.j_item_id}
                loading={loading}
                columns={columns}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JItemsSummaryTotal;
